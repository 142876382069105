html {
    height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: quicksand, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    height: 100%;
    width: 100%;
}

.loader {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 15vw;
    z-index: 9999;
}
